import Vue from "vue";

/*TITLE*/
document.title = "Colmenar II | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Colmenar II";
Vue.prototype.$subtitle = "Viviendas de obra nueva en Colmenar Viejo";

/*INTRO*/
Vue.prototype.$promoter = "Colmenar II";
Vue.prototype.$introSubtitle = "Viviendas de obra nueva en Colmenar Viejo";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "ac4-residence-anti-1l-nogal--20230112020124.jpg";
Vue.prototype.$image_kitchen = "pavimento-dover-caliza-revestimiento-china-blanco-frentes-blanco-nubol-encimera-xtone-bottega-caliza--20230112020146.jpg";
Vue.prototype.$image_bath1 = "pavimento-bottega-white-revestimiento-base-bottega-white-revestimiento-decorativo-spiga-bottega-white--20230112020119.jpg";
Vue.prototype.$image_bath2 = "pavimento-rodao-acero-revestimiento-base-rodano-acero-revestimiento-decorativo-mosaico-rodano-acero--20230112020153.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "ac4-residence-anti-1l-nogal--20230112020124.jpg",
  },
  {
    src: "ac4-residence-1l-utah--20230112020133.jpg",
  },
  {
    src: "ac4-residence-1l-nevada--20230112020141.jpg",
  },
  {
    src: "ac4-residence-1l-misuri--20230112020147.jpg",
  },
  {
    src: "ac4-residence-1l-idaho--20230112020155.jpg",
  },
  {
    src: "ac4-residence-1l-arizona--20230112020103.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "pavimento-dover-caliza-revestimiento-china-blanco-frentes-blanco-nubol-encimera-xtone-bottega-caliza--20230112020146.jpg",
  },
  {
    src: "pavimento-dover-acero-revestimiento-shine-platino-frentes-blanco-nubol-encimera-xtone-bottega-acero--20230112020154.jpg",
  },
  {
    src: "pavimento-dover-acero-revestimiento-crystal-white-frentes-blanco-nubol-encimera-xtone-bottega-acero--20230112020100.jpg",
  },
  {
    src: "pavimento-dover-acero-revestimiento-china-blanco-frentes-blanco-nubol-encimera-xtone-bottega-acero--20230112020107.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "pavimento-bottega-white-revestimiento-base-bottega-white-revestimiento-decorativo-spiga-bottega-white--20230112020119.jpg",
  },
  {
    src: "pavimento-bottega-caliza-revestimiento-base-bottega-caliza-revestimiento-decorativo-spiga-bottega-caliza--20230112020128.jpg",
  },
  {
    src: "pavimento-bottega-caliza-revestimiento-base-bottega-caliza-revestimiento-decorativo-marbella-stone--20230112020135.jpg",
  },
  {
    src: "pavimento-bottega-acero-revestimiento-base-bottega-acero-revestimiento-decorativo-spiga-bottega-acero--20230112020142.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "pavimento-rodao-acero-revestimiento-base-rodano-acero-revestimiento-decorativo-mosaico-rodano-acero--20230112020153.jpg",
  },
  {
    src: "pavimento-newport-white-revestimiento-base-newport-white--20230112020101.jpg",
  },
  {
    src: "pavimento-madagascar-blanco-revestimiento-base-madagascar-blanco-revestimiento-decorativo-ona-blanco--20230112020119.jpg",
  },
  {
    src: "pavimento-newport-white-revestimiento-base-newport-white-revestimiento-decorativo-old-white--20230112020105.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/COLMENAR_II/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/mkFQ3uKTtDM";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=colmenar%20viejo%2028770%20madrid",
    text: "Colmenar Viejo, 28770. Madrid",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%20josé%20silva%2017%20planta%202%20madrid%2028043%20",
    text: "Calle José Silva, 17. Planta 2. Madrid (28043)",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:910684245",
    text: "910684245",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@viveland.es",
    text: "info@viveland.es",
  },
];
